import React, { useEffect, useState } from 'react';

import { LogoActionBar, Main, ScrollContainer, Spinner } from 'Components/utility';

import i18n from 'Utilities/i18n';
import log from 'Utilities/log';
import mediator from 'Utilities/mediator';

import { getConnections } from 'Services/llu';

import CardConnection from './CardConnection';

const MainConnection = () => {
  const [loading, setLoading] = useState(false);
  const [connections, setConnections] = useState<Connection[]>([]);

  useEffect(() => {
    const loadLLUConnections = async () => {
      try {
        setLoading(true);

        const resp = await getConnections();

        setConnections(resp);
      } catch (error) {
        log.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadLLUConnections();
  }, []);

  return (
    <Main>
      <LogoActionBar testID="Connections" />
      {loading && <Spinner testID="Login" />}
      <ScrollContainer>
        <CardConnection
          testID="LibreLinkUp"
          logoUrl={i18n.t('Global.microcopy.common.libreLinkUpLogo')}
          infoLink="https://www.librelinkup.com"
          message={i18n.t('Connections.content.addConnection.primaryText')}
          onAddConnection={() => mediator.publish('router:navigate', '/add-connection')}
          connections={connections}
        />
      </ScrollContainer>
    </Main>
  );
};

export default MainConnection;
